















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q10',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: '$\\text{Molecule A}$', value: 'A'},
        {text: '$\\text{Molecule B}$', value: 'B'},
        {text: '$\\text{Molecule C}$', value: 'C'},
        {text: '$\\text{Molecule D}$', value: 'D'},
        {text: '$\\text{Molecule E}$', value: 'E'},
      ],
    };
  },
  computed: {
    image(): string {
      return '/img/assignments/organicVsWaterLayer2.png';
    },
  },
};
